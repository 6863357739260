@import "../website-v2/_variables.less";

.grid {
	display: grid;
	gap: var(--space-16);
	align-items: center;
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	padding: 0;

	&--highlighted {
		position: relative;

		&::before {
			--highlight-sides-offset: 10%;

			content: '';
			display: block;
			position: absolute;
			width: calc(100% + 2 * var(--highlight-sides-offset));
			left: calc(-1 * var(--highlight-sides-offset));
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 83.01563rem;
			background: var(--grid-highlight);
			filter: blur(166.5865478515625px);
		}
	}

	&--align-top {
		align-items: start;
	}

	// Three columns with centered last row
	&--three-centered {
		--gap-x: var(--space-32);
		--gap-y: var(--space-40);

		display: flex;
		flex-wrap: wrap;
		gap: var(--gap-y) var(--gap-x);
		justify-content: center;

		.card--simple {
			flex: 0 0 calc((100% - var(--gap-x)*2)/3);

			@media screen and (max-width: @screen-lg) {
				flex: 0 0 calc((100% - var(--gap-x))/2);
			}

			@media screen and (max-width: @screen-md) {
				flex: 0 0 100%;
			}
		}
	}

	// Two columns
	&--two-cols {
		grid-template-columns: 1fr 1fr;
		gap: var(--space-48);

		// Gap specific for cards in the grid
		&:has(.card--simple) {
			gap: var(--space-16);
		}

		&-one-third {
			grid-template-columns: 1fr 2fr;
			gap: var(--space-48);
		}

		&-two-thirds {
			grid-template-columns: 2fr 1fr;
			gap: var(--space-48);
		}
	}

	// Three columns
	&--three-cols {
		grid-template-columns: repeat(3, 1fr);
	}

	// Four columns
	&--four-cols {
		grid-template-columns: repeat(4, 1fr);
	}

	// Six columns
	&--six-cols {
		grid-template-columns: repeat(6, 1fr);

		// Responsive adjustments
		@media screen and (max-width: @screen-lg) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	// Responsive adjustments
	@media screen and (max-width: @screen-md) {
		grid-template-columns: 1fr;
	}

	.flex-container.stripe {
		padding: 0;

		.flex-container-content>div {
			width: 100%;
		}
	}
}

// TODO: Remove layout once bootstrap is gone
.layout {
	.flex-container.stripe:has(.grid--highlighted) {
		overflow: hidden;
	}

	.grid {
		.col {
			display: flex;
			flex-direction: column;
			gap: var(--space-24);
			padding: 0;
		}
	}
}